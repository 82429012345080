export enum PermissionScopes {
  ADMIN_USERS = 'partner_portal.admin.users',
  SALES_REPORT = 'partner_portal.sales.report',
  REGISTER_PLAN = 'partner_portal.sales.register_plan',
  ADMIN_PARTNER_PROPERTIES = 'partner_portal.admin.partner_properties',
  PARTNER_SALES_REGISTER_PLAN = 'partner.sales.register_plan',
  PARTNER_SALES_CATALOG = 'partner.sales.catalogs',
  PARTNER_SALES_CATALOG_INDIVIDUAL_PLAN = 'partner.sales.catalogs.individual_plan',
  PARTNER_SALES_CATALOG_FLAT_RATE_PLAN = 'partner.sales.catalogs.flat_rate_plan',
  PARTNER_SALES_CATALOG_BASIC_PHONE_EXTENDED_WARRANTIES = 'partner.sales.catalogs.basic_phone_extended_warranties',
  PARTNER_REPAIRS_REGULAR_REPAIR = 'partner.repairs.regular_repair',
  PARTNER_REPAIRS_REPAIRS_FIRST = 'partner.repairs.repair_first',
  PARTNER_PORTAL_SALES_REPORT = 'partner_portal.sales.report',
  PARTNER_PORTAL_ADMIN_PAYOUT_INFORMATION = 'partner_portal.admin.payout_information',
  PARTNER_SALES_REPORT = 'partner.sales.report',
  PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT = 'partner_portal.admin.payout_amount',
  PARTNER_PORTAL_SALES_REPORT_WITH_DETAILED_INFORMATION = 'partner_portal.sales.report.with_detailed_information',
  PARTNER_PORTAL_END_USERS = 'partner_portal.end_users',
  PARTNER_END_USERS = 'partner.end_users',
  PARTNER_SALES_REGISTER_DISCOUNT = 'partner.sales.register_discount',
  PARTNER_PROPERTY_SALES_REGISTER_DISCOUNT = 'partner_property.sales.register_discount',
  PARTNER_END_USERS_DELETE = 'partner.end_users.delete',
  PARTNER_PORTAL_END_USERS_DELETE = 'partner_portal.end_users.delete',
  PARTNER_SALES_REGISTER_PLAN_HOME_PLANS = 'partner.sales.register_plan.home_plans',
  PARTNER_SALES_REGISTER_PLAN_AKKO_PLANS = 'partner.sales.register_plan.akko_plans',
  PARTNER_SALES_REGISTER_PLAN_PHONE_PLANS = 'partner.sales.register_plan.phone_plans',
  PARTNER_SALES_REGISTER_PLAN_ACTIVATE_WARRANTY = 'partner.sales.register_plan.activate_warranty',
  PARTNER_SALES_REGISTER_PLAN_BUSINESS_PLANS = 'partner.sales.register_plan.business_plans',
  PARTNER_REGISTER_NEW_PLAN_VIEW_TAB = 'partner.register_new_plan.view_tab',
  PARTNER_SALES_REPORT_VIEW_TAB = 'partner.sales_report.view_tab',
  PARTNER_CLAIMS_VIEW_TAB = 'partner.claims.view_tab',
  PARTNER_TEAM_VIEW_TAB = 'partner.team.view_tab',
  PARTNER_CUSTOMERS_VIEW_TAB = 'partner.customers.view_tab',
  PARTNER_PROPERTIES_VIEW_TAB = 'partner.properties.view_tab',
  PARTNER_MARKETING_MATERIALS_VIEW_TAB = 'partner.marketing_materials.view_tab',
  PARTNER_SETTINGS_VIEW_TAB = 'partner.settings.view_tab',
  PARTNER_TRAINING_VIEW_TAB = 'partner.training.view_tab',
  PARTNER_FAQS_INFO_VIEW_TAB = 'partner.faqs_info.view_tab',
  PARTNER_PORTAL_REGISTER_NEW_PLAN_VIEW_TAB = 'partner_portal.register_new_plan.view_tab',
  PARTNER_PORTAL_SALES_REPORT_VIEW_TAB = 'partner_portal.sales_report.view_tab',
  PARTNER_PORTAL_CLAIMS_VIEW_TAB = 'partner_portal.claims.view_tab',
  PARTNER_PORTAL_TEAM_VIEW_TAB = 'partner_portal.team.view_tab',
  PARTNER_PORTAL_CUSTOMERS_VIEW_TAB = 'partner_portal.customers.view_tab',
  PARTNER_PORTAL_PROPERTIES_VIEW_TAB = 'partner_portal.properties.view_tab',
  PARTNER_PORTAL_MARKETING_MATERIALS_VIEW_TAB = 'partner_portal.marketing_materials.view_tab',
  PARTNER_PORTAL_SETTINGS_VIEW_TAB = 'partner_portal.settings.view_tab',
  PARTNER_PORTAL_TRAINING_VIEW_TAB = 'partner_portal.training.view_tab',
  PARTNER_PORTAL_FAQS_INFO_VIEW_TAB = 'partner_portal.faqs_info.view_tab',
  PARTNER_SALES_REGISTER_DISCOUNT_CATALOG_INDIVIDUAL_PLAN = 'partner.sales.register_discount.catalogs.individual_plan',
  PARTNER_SALES_REGISTER_DISCOUNT_CATALOG_FLAT_RATE_PLAN = 'partner.sales.register_discount.catalogs.flat_rate_plan',
  PARTNER_SALES_REPORT_PRODUCT_QUERY = 'partner.sales.report.product_query',
  PARTNER_END_USERS_AUTH_CLAIM_AS_USER = 'partner.end_users.auth_claim_as_user',
  PARTNER_CSV_CREATE_USER = 'partner.csv_create_user',
  PARTNER_CSV_UPDATE_USER = 'partner.csv_update_user',
  PARTNER_BUSINESS_AND_SCHOOL_VIEW_TAB = 'partner.business_and_school.view_tab',
  PARTNER_PORTAL_BUSINESS_AND_SCHOOL_VIEW_TAB = 'partner_portal.business_and_school.view_tab',
  PARTNER_DASHBOARD_VIEW_IFRAME = 'partner.dashboard.view_iframe',
  PARTNER_MARKETING_MATERIALS_VIEW_IFRAME = 'partner.marketing_materials.view_iframe',
}

export enum PermissionValues {
  ALLOW = 'ALLOW',
  DISALLOW = 'DISALLOW',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  DISALLOW_BY_SUSPENSION = 'DISALLOW_BY_SUSPENSION',
  DISALLOW_BY_AUDITING = 'DISALLOW_BY_AUDITING',
  DISALLOW_BY_REVIEWING = 'DISALLOW_BY_REVIEWING',
}
