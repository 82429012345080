import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/components/Home.vue'
import Login from '@/components/authentication/Login.vue'
import Signup from '@/components/authentication/Signup.vue'
import SignupReferralCode from '@/components/authentication/SignupReferralCode.vue'
import ResetPassword from '@/components/authentication/ResetPassword.vue'
import AddProperties from '@/components/authentication/AddProperties.vue'
import PaypalEmail from '@/components/authentication/PaypalEmail.vue'
import Dashboard from '@/components/dashboard/Dashboard.vue'
import ClaimsList from '@/components/claim/ClaimsSearch.vue'
import Sales from '@/components/sales/Sales.vue'
import Report from '@/components/sales/Report.vue'
import Team from '@/components/sales/Team.vue'
import Account from '@/components/account/Account.vue'
import RegisterNewPlan from '@/components/registration/RegisterNewPlan.vue'
import { RoutePath, RouteNames } from '@/constants/router'
import store, { ModulesIdentifiers } from '@/store'
import { AppMutations } from '@/store/modules/app/mutations'
import Representatives from '@/components/representatives/Representatives.vue'
import Properties from '@/components/properties/Properties.vue'
import SignupAndClaimPartner from '@/components/authentication/SignupAndClaimPartner.vue'
import MarketingMaterials from '@/components/marketing-materials/MarketingMaterials.vue'
import Checkout from '@/components/checkout/Checkout.vue'
import EndUsers from '@/components/end-users/EndUsers.vue'
import PayoutDetails from '@/components/payout-details/PayoutDetails.vue'
import ClaimStatusAndUpdates from '@/components/claim/ClaimStatusAndUpdates.vue'
import ClaimDeviceInfo from '@/components/claim/ClaimDeviceInfo.vue'
import ClaimsInstructionsByDevice from '@/components/claim/ClaimsInstructionsByDevice.vue'
import RegisterNewPlanIndividual from '@/components/registration/IndividualPlan.vue'
import RegisterNewPlanFamily from '@/components/registration/FamilyPlan.vue'
import RegisterNewPlanBusiness from '@/components/registration/BusinessPlan.vue'
import RegisterNewPlanActivateWarranty from '@/components/registration/ActivateWarrantyPlan.vue'
import FlatRatePlan from '@/components/registration/FlatRatePlan.vue'
import BasicPhoneExtendedWarranties from '@/components/registration/BasicPhoneExtendedWarranties.vue'
import BusinessAndSchool from '@/components/business-and-school/BusinessAndSchool.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutePath.LOGIN,
    name: RouteNames.LOGIN,
    component: Login,
  },
  {
    path: RoutePath.SIGNUP,
    name: RouteNames.SIGNUP,
    component: Signup,
  },
  {
    path: RoutePath.SIGNUP_THROUGH_DISTRIBUTOR,
    name: RoutePath.SIGNUP_THROUGH_DISTRIBUTOR,
    component: Signup,
  },
  {
    path: RoutePath.SIGNUP_REFERRAL_CODE,
    name: RouteNames.SIGNUP_REFERRAL_CODE,
    component: SignupReferralCode,
  },
  {
    path: RoutePath.SIGNUP_AND_CLAIM_PARTNER,
    name: RouteNames.SIGNUP_AND_CLAIM_PARTNER,
    component: SignupAndClaimPartner,
  },
  {
    path: RoutePath.RESET_PASSWORD,
    name: RouteNames.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    path: RoutePath.ADD_PROPERTIES,
    name: RouteNames.ADD_PROPERTIES,
    component: AddProperties,
  },
  {
    path: RoutePath.PAYPAL_EMAIL,
    name: RouteNames.PAYPAL_EMAIL,
    component: PaypalEmail,
  },
  {
    path: RoutePath.HOME,
    component: Home,
    name: RouteNames.HOME,
    children: [
      {
        path: '/',
        component: Dashboard,
        name: RouteNames.HOME_MENU,
      },
      {
        path: RoutePath.HOME_REGISTER_NEW_PLAN,
        name: RouteNames.HOME_REGISTER_NEW_PLAN,
        component: RegisterNewPlan,
        children: [
          {
            path: RoutePath.HOME_REGISTER_NEW_PLAN_INDIVIDUAL,
            name: RouteNames.HOME_REGISTER_NEW_PLAN_INDIVIDUAL,
            component: RegisterNewPlanIndividual,
          },

          {
            path: RoutePath.HOME_REGISTER_NEW_FLAT_RATE_PLAN,
            name: RouteNames.HOME_REGISTER_NEW_FLAT_RATE_PLAN,
            component: FlatRatePlan,
          },

          {
            path: RoutePath.HOME_REGISTER_BASIC_PHONE_EXTENDED_WARRANTIES,
            name: RouteNames.HOME_REGISTER_BASIC_PHONE_EXTENDED_WARRANTIES,
            component: BasicPhoneExtendedWarranties,
          },

          {
            path: RoutePath.HOME_REGISTER_NEW_PLAN_FAMILY,
            name: RouteNames.HOME_REGISTER_NEW_PLAN_FAMILY,
            component: RegisterNewPlanFamily,
          },

          {
            path: RoutePath.HOME_REGISTER_NEW_PLAN_BUSINESS,
            name: RouteNames.HOME_REGISTER_NEW_PLAN_BUSINESS,
            component: RegisterNewPlanBusiness,
          },

          {
            path: RoutePath.HOME_REGISTER_NEW_PLAN_WARRANTY,
            name: RouteNames.HOME_REGISTER_NEW_PLAN_WARRANTY,
            component: RegisterNewPlanActivateWarranty,
          },
        ],
      },
      {
        path: 'business-and-school',
        component: BusinessAndSchool,
        name: RouteNames.HOME_BUSINESS_AND_SCHOOL,
      },
      {
        path: 'claims',
        name: RouteNames.HOME_CLAIMS,
        component: ClaimsList,
        children: [
          {
            path: RoutePath.HOME_CLAIMS_STATUS,
            name: RouteNames.HOME_CLAIMS_STATUS,
            component: ClaimStatusAndUpdates,
            children: [
              {
                path: RoutePath.HOME_CLAIMS_STATUS_BY_DEVICE,
                name: RouteNames.HOME_CLAIMS_STATUS_BY_DEVICE,
                component: ClaimsInstructionsByDevice,
                props: true,
              },
            ],
          },

          {
            path: RoutePath.HOME_CLAIMS_DEVICE_INFO,
            name: RouteNames.HOME_CLAIMS_DEVICE_INFO,
            component: ClaimDeviceInfo,
          },
        ],
      },
      {
        path: RoutePath.HOME_SALES,
        name: RouteNames.HOME_SALES,
        component: Sales,
        children: [
          {
            path: RoutePath.HOME_SALES_REPORT,
            name: RouteNames.HOME_SALES_REPORT,
            component: Report,
          },

          {
            path: RoutePath.HOME_SALES_TEAM,
            name: RouteNames.HOME_SALES_TEAM,
            component: Team,
          },
        ],
      },
      {
        path: RoutePath.HOME_ACCOUNT,
        name: RouteNames.HOME_ACCOUNT,
        component: Account,
      },
      {
        path: RoutePath.HOME_PROPERTIES,
        name: RouteNames.HOME_PROPERTIES,
        component: Properties,
      },
      {
        path: RoutePath.HOME_REPRESENTATIVES,
        name: RouteNames.HOME_REPRESENTATIVES,
        component: Representatives,
      },
      {
        path: RoutePath.HOME_MARKETING_MATERIALS,
        name: RouteNames.HOME_MARKETING_MATERIALS,
        component: MarketingMaterials,
      },
      {
        path: RoutePath.HOME_CHECKOUT,
        name: RouteNames.HOME_CHECKOUT,
        component: Checkout,
      },
      {
        path: RoutePath.HOME_END_USERS,
        name: RouteNames.HOME_END_USERS,
        component: EndUsers,
      },
      {
        path: RoutePath.HOME_PAYOUT_DETAIL,
        name: RouteNames.HOME_PAYOUT_DETAIL,
        component: PayoutDetails,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const isAuthenticated = () => !!localStorage.getItem('token')
const authRequired = (name: string): boolean => {
  switch (name) {
    case RouteNames.HOME_MENU:
    case RouteNames.HOME_REPRESENTATIVES:
    case RouteNames.HOME_PROPERTIES:
    case RouteNames.HOME_ACCOUNT:
    case RouteNames.HOME_SALES:
    case RouteNames.HOME_CLAIMS:
    case RouteNames.HOME_REGISTER_NEW_PLAN:
    case RouteNames.HOME_MARKETING_MATERIALS:
    case RouteNames.HOME_CHECKOUT:
    case RouteNames.HOME_END_USERS:
    case RouteNames.HOME_PAYOUT_DETAIL:
    case RouteNames.HOME_CLAIMS_STATUS:
    case RouteNames.HOME_CLAIMS_DEVICE_INFO:
      return true
    default:
      return false
  }
}
router.beforeEach((to, from, next) => {
  const authed = isAuthenticated()
  if (authRequired(to.name as string) && !authed) {
    next({ name: RouteNames.SIGNUP })
  } else {
    next()
  }
  store.commit(
    `${ModulesIdentifiers.APP}/${AppMutations.TOGGLE_SIDEBAR}`,
    false,
  )
})

export default router
